import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import './CheatRequests.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons";

function CheatRequests() {
    const [steamLink, setSteamLink] = useState("");
    const [cheatDetails, setCheatDetails] = useState("");
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState([]);
    const [loading, setLoading] = useState(false);
    const [voteLoading, setVoteLoading] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [formVisible, setFormVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const [searchQuery, setSearchQuery] = useState(""); // Search query state

    useEffect(() => {
        fetchRequests();
        const tokenCheckInterval = setInterval(() => {
            const updatedToken = localStorage.getItem("token");
            setToken(updatedToken);
        }, 60000);
        return () => clearInterval(tokenCheckInterval);
    }, []);

    useEffect(() => {
        filterRequests();
    }, [searchQuery, activeTab, requests]);

    const filterRequests = () => {
        const filtered = requests.filter(request => 
            request.steamGameDetails?.name && request.steamGameDetails.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredRequests(filterRequestsByStatus(filtered));
    };
    
    const isValidSteamLink = (link) => /^https:\/\/store\.steampowered\.com\/app\/\d+/.test(link);

    const isTokenExpired = (token) => {
        try {
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Date.now() / 1000;
            return decodedToken.exp < currentTime;
        } catch (error) {
            return true;
        }
    };

    const fetchRequests = async () => {
        setLoading(true);
        try {
            const res = await axios.get("/api/requests");
            setRequests(res.data);
            setFilteredRequests(res.data);
        } catch (error) {
            console.error("Error fetching cheat requests:", error);
        } finally {
            setLoading(false);
        }
    };

    const submitRequest = async () => {
        setErrorMessage("");
        if (!token || isTokenExpired(token)) {
            alert("Please log in to submit a request.");
            return;
        }

        if (!steamLink) {
            setErrorMessage("Please enter a valid Steam store link.");
            return;
        }

        if (!isValidSteamLink(steamLink)) {
            setErrorMessage("Please enter a valid Steam store link.");
            return;
        }

        try {
            setLoading(true);
            const requestData = {
                steamLink,
                userId: JSON.parse(atob(token.split('.')[1])).userId
            };
            if (cheatDetails.trim()) requestData.cheatDetails = cheatDetails;

            await axios.post("/api/requests", requestData, {
                headers: { Authorization: `Bearer ${token}` }
            });

            setSteamLink("");
            setCheatDetails("");
            fetchRequests();
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "There was an error submitting the request. Please try again later.");
            console.error("Error submitting cheat request:", error);
        } finally {
            setLoading(false);
        }
    };

    const voteOnRequest = async (requestId) => {
        setErrorMessage("");
        if (!token) {
            alert("Please log in to vote.");
            return;
        }

        try {
            setVoteLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/requests/vote/${requestId}`, {}, {
                headers: { Authorization: `Bearer ${token}` }
            });
            fetchRequests();
        } catch (error) {
            setErrorMessage(error.response?.data?.message || "There was an error voting. Please try again later.");
            console.error("Error voting on cheat request:", error);
        } finally {
            setVoteLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const toggleForm = () => {
        setFormVisible(!formVisible);
    };

    const filterRequestsByStatus = (requestsList = requests) => {
        if (activeTab === 'approved') {
            return requestsList.filter(request => request.status === 'approved' || request.status === 'completed');
        } else if (activeTab === 'rejected') {
            return requestsList.filter(request => request.status === 'rejected');
        } else if (activeTab === 'pending') {
            return requestsList.filter(request => request.status === 'pending');
        }
        return requestsList.sort((a, b) => {
            const statusOrder = { 'pending': 1, 'approved': 2, 'completed': 3, 'rejected': 4 };
            return statusOrder[a.status] - statusOrder[b.status];
        });
    };

    return (
        <div className="cheatrequests-container">
            <Helmet>
                <title>Cheat Requests - Sintrix.net</title>
                <meta name="description" content="Submit and vote on cheat requests for games on Sintrix.net. Get the latest cheat tables and contribute to the community!" />
            </Helmet>

            <h2 className="cheatrequests-title">Cheat Requests</h2>
            <input 
                type="text" 
                placeholder="Search requests..." 
                value={searchQuery} 
                onChange={(e) => setSearchQuery(e.target.value)} 
                className="search-input"
            />
            <div className="tab-buttons">
                <button className={`tab-button ${activeTab === 'all' ? 'active' : ''}`} onClick={() => setActiveTab('all')}>All Requests</button>
                <button className={`tab-button ${activeTab === 'approved' ? 'active' : ''}`} onClick={() => setActiveTab('approved')}>Approved</button>
                <button className={`tab-button ${activeTab === 'rejected' ? 'active' : ''}`} onClick={() => setActiveTab('rejected')}>Rejected</button>
                <button className={`tab-button ${activeTab === 'pending' ? 'active' : ''}`} onClick={() => setActiveTab('pending')}>Pending</button>
                <button onClick={toggleForm} className="cheatrequests-toggle-button">
                    <FontAwesomeIcon icon={faSquarePlus} size="1x" />
                </button>
            </div>

            {errorMessage && <p className="global-error">{errorMessage}</p>}
            {formVisible && (
                <div className="cheatrequests-input-container">
                    <input type="text" placeholder="Steam Store Link" value={steamLink} onChange={(e) => setSteamLink(e.target.value)} className="cheatrequests-input" />
                    <input type="text" placeholder="Extra Details (optional)" value={cheatDetails} onChange={(e) => setCheatDetails(e.target.value)} className="cheatrequests-input" />
                    <button onClick={submitRequest} disabled={loading} className="cheatrequests-button">{loading ? "Submitting..." : "Submit Request"}</button>
                </div>
            )}

            {loading && <p>Loading requests...</p>}
            {filteredRequests.length === 0 && !loading ? (
                <p>No requests match your search.</p>
            ) : (
                filteredRequests.map((request) => (
                    <div key={request._id} className={`cheatrequests-card ${request.status}`}>
                        <div className="game-info">
                            <p><strong>Game:</strong> {request.steamGameDetails?.name || "Unknown Game"}</p>
                            <p><strong>SteamDB Link:</strong>{" "}
                                <a href={request.steamDBLink} target="_blank" rel="noopener noreferrer">{request.steamDBLink}</a>
                            </p>
                            {request.status === 'rejected' && request.rejectionReason && (
                                <p><strong>Rejection Reason:</strong> {request.rejectionReason}</p>
                            )}
                            {request.status === 'completed' && (
                                <p>This request is complete! You can find the table in <a href="/games">Tables</a>.</p>
                            )}
                        </div>
                        <div className="vote-section">
                            <p><strong>Votes:</strong> {request.votes}</p>
                            {request.status === 'pending' && (
                                <button onClick={() => voteOnRequest(request._id)} disabled={voteLoading[request._id]} className="cheatrequests-vote-button">
                                    {voteLoading[request._id] ? "Voting..." : "Vote"}
                                </button>
                            )}
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default CheatRequests;
