import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './AdminPanel.css';
import { selectUnknownFields } from "express-validator/lib/field-selection";
import { FaEllipsisV } from "react-icons/fa";
import Select from "react-select";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


function AdminPanel() {
    const [steamSearchQuery, setSteamSearchQuery] = useState("");
    const [steamResults, setSteamResults] = useState([]);
    const [loadingSteam, setLoadingSteam] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [selectedAppId, setSelectedAppId] = useState("");
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories] = useState([]);
    const [requests, setRequests] = useState([]);
    const [rejectedRequests, setRejectedRequests] = useState([]);
    const [users, setUsers] = useState([]);
    const [tables, setTables] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [loadingTables, setLoadingTables] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingRequests, setLoadingRequests] = useState(false);
    const [actionLoading, setActionLoading] = useState({});
    const [message, setMessage] = useState("");
    const [tableFile, setTableFile] = useState(null);
    const [tableName, setTableName] = useState("");
    const [tableVersion, setTableVersion] = useState("");
    const [activeTab, setActiveTab] = useState("content"); 
    const [isAdmin, setIsAdmin] = useState(false); 
    const [loading, setLoading] = useState(true); 
    const [description, setDescription] = useState("");
    const [version, setVersion] = useState("");
    const [releaseDate, setReleaseDate] = useState("");
    const [discontinued, setDiscontinued] = useState(false); 
    const [images, setImages] = useState([]);
    const [video, setVideo] = useState("");
    const navigate = useNavigate();
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTable, setEditingTable] = useState(null);
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const gamesPerPage = 5; // Set games per page
    const totalPages = Math.ceil(categories.length / gamesPerPage);
    const startIndex = (currentPage - 1) * gamesPerPage;
    const endIndex = startIndex + gamesPerPage;
    const currentGames = categories.slice(startIndex, endIndex);
    const [currentUserPage, setCurrentUserPage] = useState(1); // Track current page for users
    const usersPerPage = 5; // Set users per page (you can adjust this number)
    const totalUserPages = Math.ceil(users.length / usersPerPage); // Calculate total pages
    const startUserIndex = (currentUserPage - 1) * usersPerPage;
    const endUserIndex = startUserIndex + usersPerPage;
    const currentUsers = users.slice(startUserIndex, endUserIndex); // Display only users for the current page
    const [userSearchQuery, setUserSearchQuery] = useState(""); // State for search query
    const [filteredUsers, setFilteredUsers] = useState([]); // State for filtered users
    const [showActionMenu, setShowActionMenu] = useState(null);
    const [uniqueVisitorsSummary, setUniqueVisitorsSummary] = useState(0);
    const [totalRequestsSummary, setTotalRequestsSummary] = useState(0);    
    const [chartData, setChartData] = useState(null);
    const [timeRange, setTimeRange] = useState("24h"); // Default to 24-hour data
    const [voterVisibility, setVoterVisibility] = useState({}); // Track voter list visibility for each request
    const [showVoters, setShowVoters] = useState({});
    const [showRequestActionMenu, setShowRequestActionMenu] = useState(null);
    const [showCategoryForm, setShowCategoryForm] = useState(false);
    const [showTableForm, setShowTableForm] = useState(false);
    const [transitioning, setTransitioning] = useState(false);


    
    const [confirmationDialog, setConfirmationDialog] = useState({
        visible: false,
        action: null,
        userId: null
    });
    // Calculate filtered pagination details
    const filteredTotalUserPages = Math.ceil(filteredUsers.length / usersPerPage);
    const filteredStartUserIndex = (currentUserPage - 1) * usersPerPage;
    const filteredEndUserIndex = filteredStartUserIndex + usersPerPage;
    const filteredCurrentUsers = filteredUsers.slice(filteredStartUserIndex, filteredEndUserIndex);
    const [selectedFilter, setSelectedFilter] = useState("pending");
    const filteredRequests = requests.filter((req) => {
        switch (selectedFilter) {
            case "pending":
                return req.status === "pending";
            case "approved":
                return req.status === "approved";
            case "rejected":
                return req.status === "rejected";
            case "completed":
                return req.status === "completed";
            case "all":
            default:
                return true; // Show all requests
        }
    });
    
    function convertToSlug(text) {
        return text
            .toLowerCase()                      // Convert to lowercase
            .replace(/[\s\W-]+/g, '-')           // Replace spaces and non-word characters with hyphens
            .replace(/^-+/, '')                  // Remove leading hyphens
            .replace(/-+$/, '');                 // Remove trailing hyphens
    }    

    const toggleRequestActionMenu = (requestId) => {
        setShowRequestActionMenu((prev) => (prev === requestId ? null : requestId));
    };
    
    // Toggle the action menu visibility for a specific user
    const toggleActionMenu = (userId) => {
        setShowActionMenu((prev) => (prev === userId ? null : userId));
    };

    // Close the dropdown if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check for clicks outside of both dropdowns
            if (showActionMenu && !event.target.closest(".context-menu")) {
                setShowActionMenu(null);
            }
            if (showRequestActionMenu && !event.target.closest(".dropdown-menu")) {
                setShowRequestActionMenu(null);
            }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showActionMenu, showRequestActionMenu]);
    

    const toggleVoterVisibility = (requestId) => {
        setVoterVisibility((prev) => ({
            ...prev,
            [requestId]: !prev[requestId],
        }));
    };
    
    // Define the verifyUser function
    const verifyUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.post(`/api/admin/users/${userId}/verify`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User verified successfully.");
            fetchUsers(); // Refresh the user list
        } catch (error) {
            setMessage("Error verifying user.");
            console.error("Error verifying user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    // Function to open the confirmation dialog
    const openConfirmationDialog = (action, userId) => {
        setConfirmationDialog({ visible: true, action, userId });
    };

    // Function to close the confirmation dialog
    const closeConfirmationDialog = () => {
        setConfirmationDialog({ visible: false, action: null, userId: null });
    };

    // Function to confirm an action in the dialog
    const confirmAction = async () => {
        const { action, userId } = confirmationDialog;
        if (action && userId) {
            await action(userId); // Execute the action (like delete, ban, or unban)
        }
        closeConfirmationDialog();
    };

    // Wrapper functions for actions that need confirmation
    const deleteUserWithConfirmation = (userId) => {
        openConfirmationDialog(deleteUser, userId);
    };

    const banUserWithConfirmation = (userId) => {
        openConfirmationDialog(banUser, userId);
    };

    const unbanUserWithConfirmation = (userId) => {
        openConfirmationDialog(unbanUser, userId);
    };
    
    
    
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    // Function to change tabs
    const changeTab = (tab) => {
        setActiveTab(tab);
    };

    // Fetch initial data when component loads
    useEffect(() => {
        checkAdminStatus();
        fetchUsers();
        fetchCategories();
    }, [navigate]);


    
    
    // Filter users based on search query for username, discord username, or email
    useEffect(() => {
        setFilteredUsers(
            users.filter((user) => 
                user.username.toLowerCase().includes(userSearchQuery.toLowerCase()) ||
                (user.discordUsername && user.discordUsername.toLowerCase().includes(userSearchQuery.toLowerCase())) ||
                (user.email && user.email.toLowerCase().includes(userSearchQuery.toLowerCase()))
            )
        );
        setCurrentUserPage(1); // Reset to the first page when search query changes
    }, [userSearchQuery, users]);
    

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post("/api/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;
            localStorage.setItem("token", accessToken);
            return accessToken;
        } catch (error) {
            console.error("Error refreshing access token:", error);
            navigate("/login");
            throw error;
        }
    };

    
    const fetchAnalytics = async () => {
        try {
            const response = await axios.get(`/api/admin/cloudflare-analytics?range=${timeRange}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            
            const zones = response.data?.zones || [];
            const labels = zones.map(zone => new Date(zone.dimensions.timeslot).toLocaleString());
            const uniqueVisitorsData = zones.map(zone => zone.uniq.uniques);
            const requestsData = zones.map(zone => zone.sum.requests);
    
            // Calculate summaries
            const uniqueVisitorsTotal = uniqueVisitorsData.reduce((a, b) => a + b, 0);
            const totalRequests = requestsData.reduce((a, b) => a + b, 0);
            
            setUniqueVisitorsSummary(uniqueVisitorsTotal);
            setTotalRequestsSummary(totalRequests);
    
            // Set the data in the required format for Chart.js
            setChartData({
                uniqueVisitors: {
                    labels,
                    datasets: [{
                        label: "Unique Visitors",
                        data: uniqueVisitorsData,
                        borderColor: "rgba(54, 162, 235, 1)",
                        fill: true,
                        tension: 0.1,
                    }]
                },
                requests: {
                    labels,
                    datasets: [{
                        label: "Total Requests",
                        data: requestsData,
                        borderColor: "rgba(75, 192, 192, 1)",
                        fill: true,
                        tension: 0.1,
                    }]
                }
            });
        } catch (error) {
            console.error("Error fetching analytics data:", error.response || error.message);
            setMessage("Error loading analytics data.");
        }
    };
    
    
    
    useEffect(() => {
        if (activeTab === 'analytics') {
            fetchAnalytics();
        }
    }, [activeTab, timeRange]);
    
    const chartOptions = {
        responsive: true,
        plugins: {
            legend: { position: "top" },
            title: { display: true, text: `Unique Visitors and Requests for ${timeRange}` },
        },
        scales: {
            x: { title: { display: true, text: "Time" } },
            y: { title: { display: true, text: "Count" } },
        },
    };
    
    


    const isTokenExpired = (token) => {
        try {
            const decoded = JSON.parse(atob(token.split(".")[1]));
            const currentTime = Math.floor(Date.now() / 1000);
            return decoded.exp < currentTime;
        } catch (error) {
            return true;
        }
    };

    // Check admin status directly from backend
    const checkAdminStatus = async () => {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");

        if (!token) {
            navigate("/login");
            return;
        }

        try {
            if (isTokenExpired(token)) {
                if (refreshToken) {
                    const newAccessToken = await refreshAccessToken(refreshToken);
                    console.log("Access token refreshed");
                } else {
                    navigate("/login");
                    return;
                }
            }

            // Check admin status via backend
            const res = await axios.get("/api/auth/check-admin", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });

            if (res.data.isAdmin) {
                setIsAdmin(true); // Set isAdmin to true if verified
                fetchRequests();
                fetchUsers();
            } else {
                navigate("/login");
            }

        } catch (error) {
            console.error("Error verifying admin status:", error);
            navigate("/login");
        } finally {
            setLoading(false); // Stop loading after check
        }
    };

    const completeRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/admin/complete/${requestId}`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("Request marked as completed successfully.");
            fetchRequests(); // Refresh the requests after completion
        } catch (error) {
            setMessage("Error marking request as completed.");
            console.error("Error completing request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };
    

    const fetchRequests = async () => {
        setLoadingRequests(true);
        try {
            const res = await axios.get("/api/requests", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
    
            const enrichedRequests = await Promise.all(
                res.data.map(async (request) => {
                    try {
                        // Fetch the requester's username
                        const userResponse = await axios.get(`/api/admin/users/${request.userId}`, {
                            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                        });
    
                        // Fetch the voters' information
                        const voters = await Promise.all(
                            request.voters.map(async (voterId) => {
                                try {
                                    const voterResponse = await axios.get(`/api/admin/users/${voterId}`, {
                                        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                                    });
                                    return voterResponse.data.username;
                                } catch (voterError) {
                                    console.error("Error fetching voter data:", voterError);
                                    return "Unknown Voter";
                                }
                            })
                        );
    
                        return {
                            ...request,
                            username: userResponse.data.username || "Unknown User",
                            voters, // Include the list of voter usernames
                        };
                    } catch (userError) {
                        console.error("Error fetching request data:", userError);
                        return { ...request, username: "Unknown User", voters: [] };
                    }
                })
            );
    
            setRequests(enrichedRequests); // Set all requests with enriched data
        } catch (error) {
            setMessage("Error fetching requests.");
            console.error("Error fetching requests:", error);
        } finally {
            setLoadingRequests(false);
        }
    };
    
    
    const handleTransitionToTableForm = () => {
        setTransitioning(true);
        setTimeout(() => {
            setShowCategoryForm(false);
            setShowTableForm(true);
            setTransitioning(false);
        }, 500); // Match this with CSS transition time
    };
    
    const handleTransitionToCategoryForm = () => {
        setTransitioning(true);
        setTimeout(() => {
            setShowCategoryForm(true);
            setShowTableForm(false);
            setTransitioning(false);
        }, 500); // Match this with CSS transition time
    };
     

    const fetchCategories = async () => {
        try {
            const response = await axios.get('/api/admin/categories', {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error fetching categories: ", error);
        }
    };
    

    const nextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };
    
    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    const fetchUsers = async () => {
        setLoadingUsers(true);
        try {
            const res = await axios.get("/api/admin/users", {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setUsers(res.data);
            setFilteredUsers(res.data); // Initialize filtered users with all users
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setLoadingUsers(false);
        }
    };

    const fetchTables = async (category) => {
        if (!category || !category.slug) {
            setTables([]); // Reset tables if no valid category is selected
            return;
        }
        setLoadingTables(true);
        try {
            // Fetch tables by slug, not by _id
            const response = await axios.get(`/api/admin/tables/${category.slug}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setTables(response.data);
        } catch (error) {
            setMessage("Error fetching tables.");
            console.error("Error fetching tables:", error);
            setTables([]); // Reset tables if error occurs
        } finally {
            setLoadingTables(false);
        }
    };
    
    
    const handleModifyTable = (table) => {
        // Set the table being edited
        setEditingTable(table);
        setTableName(table.name); // Pre-fill the name
        setTableVersion(table.version); // Pre-fill the version
        setDescription(table.description); // Pre-fill the description
    };
    
    const handleUpdateTable = async (e) => {
        e.preventDefault();
        
        if (!editingTable || !tableName || !tableVersion || !description) {
            setMessage("Please fill in all fields.");
            return;
        }
    
        const updatedTableData = {
            name: tableName,
            version: tableVersion,
            description,
        };
    
        try {
            setLoadingTables(true);
            await axios.put(`/api/admin/tables/${editingTable._id}`, updatedTableData, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });
            setMessage("Table updated successfully.");
            setEditingTable(null); // Exit edit mode
            fetchTables(selectedCategory); // Refresh the tables list
        } catch (error) {
            setMessage("Error updating table.");
            console.error("Error updating table:", error);
        } finally {
            setLoadingTables(false);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setNewCategoryName(category.name);
        setSelectedAppId(category.appid);
        setDiscontinued(category.discontinued || false); // Set discontinued status correctly
        setVideo(category.video || "");
        setImages(category.images ? category.images.join(', ') : "");
    };
    
    
    
    const handleCancelEditCategory = () => {
        setEditingCategory(null); 
        setNewCategoryName("");       
        setSelectedAppId("");   
        setVideo("");
        setImages("");  
        setDiscontinued(false); // Reset discontinued status
    };
    

    const handleCancelEdit = () => {
        // Reset all form fields
        setEditingTable(null);  // Exit edit mode
        setTableName("");       // Clear table name
        setTableVersion("");    // Clear table version
        setDescription("");     // Clear description
    };
    
    

    const handleAddOrUpdateCategory = async (e) => {
        e.preventDefault();
    
        // Ensure required fields are filled
        if (!newCategoryName || !selectedAppId) {
            setMessage("Please enter all required fields.");
            return;
        }
    
        let appId = selectedAppId; // Declare and initialize appId at once
        const steamAppIdRegex = /(?:https?:\/\/)?(?:store\.)?steam(?:community|powered)?\.com\/app\/(\d+)/i;
        const match = steamSearchQuery.match(steamAppIdRegex);
        
        if (match) {
            appId = match[1]; // Extracted App ID from the URL if match is found
        } else if (!/^\d+$/.test(appId)) {
            setMessage("Please enter a valid Steam App ID or URL.");
            return;
        }
        
    
        // Convert the category name to a slug before saving
        const slug = convertToSlug(newCategoryName);
    
        const categoryData = {
            name: newCategoryName,
            slug: slug,  // Added slug
            appid: appId,
            video: video || "",
            images: images ? images.split(",").map(img => img.trim()) : [],
            discontinued: discontinued // Include discontinued status
        };
    
        try {
            setLoadingCategories(true);
            if (editingCategory) {
                await axios.put(`/api/admin/categories/${editingCategory.slug}`, categoryData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setMessage("Category updated successfully.");
            } else {
                await axios.post("/api/admin/categories", categoryData, {
                    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                });
                setMessage("Category added successfully.");
            }
    
            // Clear form and reset state
            setEditingCategory(null);
            setNewCategoryName("");
            setSelectedAppId("");
            setVideo("");
            setImages("");
            setDiscontinued(false); // Reset discontinued status
            fetchCategories(); // Refresh category list
    
        } catch (error) {
            setMessage("Error saving category.");
            console.error("Error saving category:", error);
        } finally {
            setLoadingCategories(false);
        }
    };
    
    
    
    
    const deleteCategory = async (categoryId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [categoryId]: true }));
            await axios.delete(`/api/admin/categories/${categoryId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("Category deleted successfully.");
            fetchCategories();
        } catch (error) {
            setMessage("Error deleting category: " + error.message);
            console.error("Error deleting category:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [categoryId]: false }));
        }
    };

    const approveRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.post(`/api/admin/approve/${requestId}`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("Request approved successfully.");
            fetchRequests();
        } catch (error) {
            setMessage("Error approving request.");
            console.error("Error approving request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const rejectRequest = async (requestId) => {
        try {
            // Prompt the admin for a rejection reason
            const rejectionReason = prompt("Please enter a reason for rejecting this request:");
    
            // If the admin cancels the prompt or leaves it empty, do not proceed
            if (!rejectionReason) {
                alert("Rejection reason is required.");
                return;
            }
    
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
    
            // Send the rejection reason to the backend
            await axios.post(`/api/admin/reject/${requestId}`, 
            { rejectionReason },  // Include the reason in the request body
            {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
    
            setMessage("Request rejected successfully.");
            fetchRequests();  // Refresh the requests after rejection
        } catch (error) {
            setMessage("Error rejecting request.");
            console.error("Error rejecting request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };
    
    const deleteRequest = async (requestId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [requestId]: true }));
            await axios.delete(`/api/admin/delete/${requestId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("Request deleted successfully.");
            fetchRequests();
        } catch (error) {
            setMessage("Error deleting request.");
            console.error("Error deleting request:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [requestId]: false }));
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setTableFile(file);
        if (file) {
            setTableName(file.name);
        }
    };

    const handleTableUpload = async (e) => {
        e.preventDefault();
        if (!tableFile || !selectedCategory || !tableName || !tableVersion || !description) {
            setMessage("Please select a category, table file, and provide a version, name, and description.");
            return;
        }
    
        const formData = new FormData();
        formData.append("tableFile", tableFile);
        formData.append("category", selectedCategory._id);
        formData.append("name", tableName);
        formData.append("version", tableVersion);
        formData.append("description", description);
    
        try {
            setLoadingTables(true);
            await axios.post("/api/admin/upload-table", formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'multipart/form-data'
                },
            });
            setMessage("Table uploaded successfully.");
            setTableFile(null);
            setTableName("");
            setTableVersion("");
            setDescription("");  // Reset description after upload
            fetchTables(selectedCategory);
        } catch (error) {
            setMessage("Error uploading table.");
            console.error("Error uploading table:", error);
        } finally {
            setLoadingTables(false);
        }
    };
    
    

    const deleteTable = async (tableId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [tableId]: true }));
            await axios.delete(`/api/admin/delete-table/${tableId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("Table deleted successfully.");
            fetchTables(selectedCategory);
        } catch (error) {
            console.error("Error deleting table:", error.response?.data || error.message);
            setMessage("Error deleting table.");
        } finally {
            setActionLoading((prev) => ({ ...prev, [tableId]: false }));
        }
    };

    const changeUserRole = async (userId, newRole) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.put(`/api/admin/users/${userId}`, { role: newRole }, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User role updated successfully.");
            fetchUsers();
        } catch (error) {
            setMessage("Error updating user role.");
            console.error("Error updating user role:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    const banUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.post(`/api/admin/users/${userId}/ban`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User banned successfully.");
            fetchUsers();
        } catch (error) {
            setMessage("Error banning user.");
            console.error("Error banning user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    const unbanUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.post(`/api/admin/users/${userId}/unban`, null, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User unbanned successfully.");
            fetchUsers();
        } catch (error) {
            setMessage("Error unbanning user.");
            console.error("Error unbanning user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };


    const deleteUser = async (userId) => {
        try {
            setActionLoading((prev) => ({ ...prev, [userId]: true }));
            await axios.delete(`/api/admin/users/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            });
            setMessage("User deleted successfully.");
            fetchUsers();
        } catch (error) {
            setMessage("Error deleting user.");
            console.error("Error deleting user:", error);
        } finally {
            setActionLoading((prev) => ({ ...prev, [userId]: false }));
        }
    };

    const searchSteamGames = async () => {
        if (!steamSearchQuery) {
            setMessage("Please enter a game name or Steam link to search.");
            return;
        }

        const steamAppIdRegex = /(?:https?:\/\/)?(?:store\.)?steam(?:community|powered)?\.com\/app\/(\d+)/i;
        const match = steamSearchQuery.match(steamAppIdRegex);
        const appId = match ? match[1] : null;

        setLoadingSteam(true);
        setMessage("");

        try {
            const searchQuery = appId || steamSearchQuery;
            const response = await axios.get(`/api/steam/search?query=${searchQuery}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            });

            setSteamResults(response.data);

            if (response.data.length === 0) {
                setMessage("No games found. Try entering the game name or App ID.");
            }
        } catch (error) {
            setMessage("Error searching Steam games.");
            console.error("Error searching Steam games:", error);
        } finally {
            setLoadingSteam(false);
        }
    };
    

    // Render only if the user is an admin
    if (loading) {
        return <p>Loading...</p>;
    }

    if (!isAdmin) {
        return null;
    }

    return (
        <div className="adminpanel-container">
            <h2 className="adminpanel-title">Sintrix - Admin Panel</h2>
            {message && (
                <p className={message.includes("Error") ? "adminpanel-error" : "adminpanel-success"}>
                    {message}
                </p>
            )}
    
            {/* Tab Navigation */}
            <div className="adminpanel-tabs">
                <button
                    className={`adminpanel-tab ${activeTab === 'content' ? 'active' : ''}`}
                    onClick={() => changeTab('content')}
                >
                    Manage Content
                </button>
                <button
                    className={`adminpanel-tab ${activeTab === 'requests' ? 'active' : ''}`}
                    onClick={() => changeTab('requests')}
                >
                    Manage Requests
                </button>
                <button
                    className={`adminpanel-tab ${activeTab === 'users' ? 'active' : ''}`}
                    onClick={() => changeTab('users')}
                >
                    Manage Users
                </button>
                <button
                    className={`adminpanel-tab ${activeTab === 'analytics' ? 'active' : ''}`}
                    onClick={() => changeTab('analytics')}
                >
                    Analytics
                </button>
            </div>

            {/* Analytics */}
            {activeTab === 'analytics' && (
                <div>
                    <h3>Last 24h Analytics</h3>

                    {/* Unique Visitors Summary and Chart */}
                    <div className="analytics-chart-wrapper">
                        <h4>Unique Visitors</h4>
                        <p className="analytics-summary-number">{uniqueVisitorsSummary}</p>
                        <div className="analytics-chart-container">
                            {chartData?.uniqueVisitors ? (
                                <Line 
                                    data={chartData.uniqueVisitors} 
                                    options={{ 
                                        responsive: true, 
                                        maintainAspectRatio: false, 
                                        plugins: { legend: { display: false } }, 
                                        scales: { 
                                            x: { display: false }, 
                                            y: { beginAtZero: true } 
                                        } 
                                    }} 
                                />
                            ) : (
                                <p>Loading unique visitors data...</p>
                            )}
                        </div>
                    </div>

                    {/* Total Requests Summary and Chart */}
                    <div className="analytics-chart-wrapper">
                        <h4>Total Requests</h4>
                        <p className="analytics-summary-number">{totalRequestsSummary}</p>
                        <div className="analytics-chart-container">
                            {chartData?.requests ? (
                                <Line 
                                    data={chartData.requests} 
                                    options={{ 
                                        responsive: true, 
                                        maintainAspectRatio: false, 
                                        plugins: { legend: { display: false } }, 
                                        scales: { 
                                            x: { display: false }, 
                                            y: { beginAtZero: true } 
                                        } 
                                    }} 
                                />
                            ) : (
                                <p>Loading requests data...</p>
                            )}
                        </div>
                    </div>
                </div>
            )}



            {/* Tab Content for Manage Content */}
            {activeTab === 'content' && (
                <div>
                    {/* Manage Content Header */}
                    <h3>Manage Content</h3>

                    {/* Dropdown for Categories */}
                    <div className="dropdown-container">
                    <Select
                            options={categories.map((category) => ({
                                value: category.slug,  // Use slug as value
                                label: category.name,
                            }))}
                            onChange={(selectedOption) => {
                                const selected = categories.find(
                                    (cat) => cat.slug === selectedOption.value  // Match by slug
                                );
                                setSelectedCategory(selected);
                                setEditingCategory(selected); // Set editingCategory for form state
                                setNewCategoryName(selected?.name || "");
                                setSelectedAppId(selected?.appid || "");
                                setVideo(selected?.video || "");
                                setImages(selected?.images?.join(", ") || "");
                                setDiscontinued(selected?.discontinued || false);
                                fetchTables(selected); // Fetch tables for the selected category
                            }}
                            placeholder="Select a category..."
                            className="category-dropdown"
                            styles={{
                                control: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--dropdown-background)",
                                    color: "var(--dropdown-text-color)",
                                    border: "1px solid var(--border-color)",
                                    borderRadius: "8px",
                                    padding: "6px",
                                }),
                                singleValue: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-text-color)",
                                }),
                                menu: (base) => ({
                                    ...base,
                                    backgroundColor: "var(--dropdown-background)",
                                    border: "1px solid var(--border-color)",
                                    borderRadius: "8px",
                                }),
                                option: (base, { isFocused, isSelected }) => ({
                                    ...base,
                                    backgroundColor: isSelected
                                        ? "var(--link-color)"
                                        : isFocused
                                        ? "var(--dropdown-hover-background)"
                                        : "var(--dropdown-background)",
                                    color: isSelected
                                        ? "var(--button-text)"
                                        : "var(--dropdown-text-color)",
                                    cursor: "pointer",
                                    padding: "10px",
                                }),
                                placeholder: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-placeholder-color)",
                                }),
                                input: (base) => ({
                                    ...base,
                                    color: "var(--dropdown-text-color)", // Ensure typed text is visible
                                }),
                            }}
                        />
                    </div>

                    <div className="form-switch-buttons" style={{ marginTop: "20px" }}>
                        <button onClick={handleTransitionToCategoryForm} className="switch-button">
                            Manage Games
                        </button>
                        <button onClick={handleTransitionToTableForm} className="switch-button">
                            Manage Tables
                        </button>
                    </div>


                    {/* Conditionally Render Forms */}
                    {showCategoryForm && (
                        <div className={`form-container ${transitioning ? "transitioning" : ""}`}>
                            {/* Manage Games Section */}
                            <h3>{editingCategory ? "Modify Game" : "Manage Games"}</h3>
                            <form onSubmit={handleAddOrUpdateCategory}>
                                <input
                                    type="text"
                                    placeholder="Game Name"
                                    value={newCategoryName}
                                    onChange={(e) => setNewCategoryName(e.target.value)}
                                    className="upload-input"
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="App ID"
                                    value={selectedAppId}
                                    onChange={(e) => setSelectedAppId(e.target.value)}
                                    className="upload-input"
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Video URL (optional)"
                                    value={video}
                                    onChange={(e) => setVideo(e.target.value)}
                                    className="upload-input"
                                />
                                <input
                                    type="text"
                                    placeholder="Image URLs (comma-separated)"
                                    value={images}
                                    onChange={(e) => setImages(e.target.value)}
                                    className="upload-input"
                                />
                                <div className="checkbox-wrapper">
                                    <input
                                        type="checkbox"
                                        id="discontinued"
                                        checked={discontinued}
                                        onChange={(e) => setDiscontinued(e.target.checked)}
                                    />
                                    <label htmlFor="discontinued" className="custom-checkbox"></label>
                                    <label htmlFor="discontinued">Discontinued</label>
                                </div>
                                <button type="submit" className="upload-button" disabled={loadingCategories}>
                                    {loadingCategories ? (editingCategory ? "Updating..." : "Adding...") : (editingCategory ? "Update Category" : "Add Category")}
                                </button>

                                {editingCategory && (
                                    <>
                                        <button
                                            type="button"
                                            className="delete-button"
                                            onClick={() => deleteCategory(selectedCategory._id)}
                                            disabled={actionLoading[selectedCategory._id]}
                                        >
                                            {actionLoading[selectedCategory._id] ? "Deleting..." : "Delete Category"}
                                        </button>
                                        <button type="button" className="cancel-button" onClick={handleCancelEditCategory}>
                                            Cancel Edit
                                        </button>
                                    </>
                                )}
                            </form>
                        </div>
                    )}

                    {showTableForm && (
                        <div className={`form-container ${transitioning ? "transitioning" : ""}`}>
                            {/* Manage Tables Section */}
                            <h3>Manage Tables</h3>

                            {selectedCategory && (
                                <p className="selected-category-info">
                                    <strong>Selected Game:</strong> {selectedCategory.name}
                                </p>
                            )}

                            {editingTable ? (
                                <form onSubmit={handleUpdateTable} className="Table-Form">
                                    <h3>Modify Table</h3>
                                    <input
                                        type="text"
                                        value={tableName}
                                        onChange={(e) => setTableName(e.target.value)}
                                        placeholder="Table Name"
                                        className="upload-input"
                                    />
                                    <input
                                        type="text"
                                        value={tableVersion}
                                        onChange={(e) => setTableVersion(e.target.value)}
                                        placeholder="Table Version"
                                        className="upload-input"
                                    />
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Table Description"
                                        className="upload-input"
                                    />
                                    <button type="submit" className="upload-button">Update Table</button>
                                    <button
                                        type="button"
                                        className="cancel-button"
                                        onClick={handleCancelEdit}
                                    >
                                        Cancel
                                    </button>
                                </form>
                            ) : (
                                <form onSubmit={handleTableUpload}>
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                        className="upload-input"
                                    />
                                    <input
                                        type="text"
                                        value={tableName}
                                        onChange={(e) => setTableName(e.target.value)}
                                        placeholder="Table Name"
                                        className="upload-input"
                                    />
                                    <input
                                        type="text"
                                        value={tableVersion}
                                        onChange={(e) => setTableVersion(e.target.value)}
                                        placeholder="Table Version"
                                        className="upload-input"
                                    />
                                    <textarea
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder="Table Description"
                                        className="upload-input"
                                    />
                                    <button type="submit" className="upload-button" disabled={loadingTables}>
                                        {loadingTables ? "Uploading..." : "Upload Table"}
                                    </button>
                                </form>
                            )}

                            {loadingTables ? (
                                <p>Loading tables...</p>
                            ) : (
                                tables.length > 0 && tables.map((table, index) => (
                                    <div key={`${table._id}-${index}`} className="adminpanel-card">
                                        <p><strong>Table Name:</strong> {table.name}</p>
                                        <p><strong>Version:</strong> {table.version}</p>
                                        <p><strong>Description:</strong> {table.description || "No description provided"}</p>
                                        <button onClick={() => handleModifyTable(table)} className="adminpanel-button modify">
                                            Modify
                                        </button>
                                        <button onClick={() => deleteTable(table._id)} disabled={actionLoading[table._id]} className="adminpanel-button delete">
                                            {actionLoading[table._id] ? "Deleting..." : "Delete Table"}
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>
                    )}
                </div>
            )}

    

            {/* Tab Content for Manage Requests */}
            {activeTab === 'requests' && (
                <div>
                    <h3>Cheat Requests</h3>

                    {/* Filter Buttons for Requests */}
                    <div className="request-filter-tabs">
                        <button
                            className={`request-filter-tab ${selectedFilter === 'pending' ? 'active' : ''}`}
                            onClick={() => handleFilterChange("pending")}
                        >
                            Pending
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'approved' ? 'active' : ''}`}
                            onClick={() => handleFilterChange("approved")}
                        >
                            Approved
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'rejected' ? 'active' : ''}`}
                            onClick={() => handleFilterChange("rejected")}
                        >
                            Rejected
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'completed' ? 'active' : ''}`}
                            onClick={() => handleFilterChange("completed")}
                        >
                            Completed
                        </button>
                        <button
                            className={`request-filter-tab ${selectedFilter === 'all' ? 'active' : ''}`}
                            onClick={() => handleFilterChange("all")}
                        >
                            All
                        </button>
                    </div>

                    {loadingRequests && <p>Loading requests...</p>}
                    {filteredRequests.length === 0 && !loadingRequests ? (
                        <p>No requests to manage</p>
                    ) : (
                        filteredRequests.map((request, index) => (
                            <div key={`${request._id}-${index}`} className="adminpanel-card">
                                {/* Flex container for title and menu button */}
                                <div className="card-header">
                                    <h4>Game Name: {request.steamGameDetails?.name || "Unknown Game"}</h4>
                                    <button onClick={() => toggleRequestActionMenu(request._id)} className="menu-button">
                                        <FaEllipsisV />
                                    </button>
                                </div>
                                
                                {/* Request Details */}
                                <p><strong>Request by:</strong> {request.username}</p>
                                <p><strong>Cheat Details:</strong> {request.cheatDetails}</p>
                                <p><strong>Votes:</strong> {request.votes}</p>
                                <p><strong>Status:</strong> {request.status}</p>
                                {request.status === "rejected" && (
                                    <p><strong>Reason:</strong> {request.rejectionReason}</p>
                                )}

                                {/* Show Voters Button */}
                                <button
                                    onClick={() =>
                                        setShowVoters((prev) => ({
                                            ...prev,
                                            [request._id]: !prev[request._id],
                                        }))
                                    }
                                    className="adminpanel-button show-voters"
                                >
                                    {showVoters[request._id] ? "Hide Voters" : "Show Voters"}
                                </button>

                                {/* Conditionally render styled voters list */}
                                {showVoters[request._id] && (
                                    <div className="voters-list">
                                        <h5>Voters:</h5>
                                        <ul className="voter-list">
                                            {request.voters.map((voter, i) => (
                                                <li key={i}>{voter}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                {/* Dropdown Menu for Actions */}
                                {showRequestActionMenu === request._id && (
                                    <div className="dropdown-menu">
                                        {request.status === "approved" && (
                                            <button
                                                onClick={() => completeRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Completing..." : "Complete"}
                                            </button>
                                        )}
                                        {request.status === "pending" && (
                                            <button
                                                onClick={() => approveRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Approving..." : "Approve"}
                                            </button>
                                        )}
                                        {request.status !== "completed" && (
                                            <button
                                                onClick={() => rejectRequest(request._id)}
                                                disabled={actionLoading[request._id]}
                                                className="dropdown-item"
                                            >
                                                {actionLoading[request._id] ? "Rejecting..." : "Reject"}
                                            </button>
                                        )}
                                        <button
                                            onClick={() => deleteRequest(request._id)}
                                            disabled={actionLoading[request._id]}
                                            className="dropdown-item"
                                        >
                                            {actionLoading[request._id] ? "Deleting..." : "Delete"}
                                        </button>
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            )}



    
            {/* Manage Users */}
            {activeTab === 'users' && (
                <div>
                    <h3>Manage Users</h3>
                    <p className="user-count">Total Users: {users.length}</p>
                    {/* User Search Input */}
                    <input
                        type="text"
                        placeholder="Search by username, discord, or email..."
                        value={userSearchQuery}
                        onChange={(e) => setUserSearchQuery(e.target.value)}
                        className="user-search-input"
                    />
                    {loadingUsers && <p>Loading users...</p>}
                    {filteredUsers.length === 0 && !loadingUsers ? (
                        <p>No users found</p>
                    ) : (
                        filteredCurrentUsers.map((user) => (
                            <div key={user._id} className="adminpanel-card">
                                <p><strong>Username:</strong> {user.username}</p>
                                <p><strong>ID:</strong> {user._id}</p>
                                <p><strong>Created:</strong> {new Date(user.createdAt).toLocaleString()}</p>
                                <p><strong>Email:</strong> {user.email || "N/A"}</p>
                                <p><strong>Verified:</strong> {user.isVerified ? "Yes" : "No"}</p>
                                <p><strong>Discord:</strong> {user.discordUsername || "Not Linked"}</p>
                                <p><strong>Role:</strong> {user.role}</p>
                                <p><strong>Status:</strong> {user.banned ? "Banned" : "Unbanned"}</p>
                        
                                {/* Context Menu for Actions */}
                                <div className="context-menu">
                                    <button onClick={() => toggleActionMenu(user._id)} className="menu-button">
                                        <FaEllipsisV />
                                    </button>
                                    {showActionMenu === user._id && (
                                        <div className="dropdown-menu">
                                            {!user.isVerified && (
                                                <button
                                                    onClick={() => verifyUser(user._id)}
                                                    disabled={actionLoading[user._id]}
                                                >
                                                    {actionLoading[user._id] ? "Verifying..." : "Verify User"}
                                                </button>
                                            )}
                                            {user.role !== 'admin' && (
                                                <button onClick={() => changeUserRole(user._id, "admin")}>
                                                    Make Admin
                                                </button>
                                            )}
                                            {user.role !== 'user' && (
                                                <button onClick={() => changeUserRole(user._id, "user")}>
                                                    Make User
                                                </button>
                                            )}
                                            <button onClick={() => deleteUserWithConfirmation(user._id)}>
                                                Delete User
                                            </button>
                                            {user.banned ? (
                                                <button onClick={() => unbanUserWithConfirmation(user._id)}>
                                                    Unban
                                                </button>
                                            ) : (
                                                <button onClick={() => banUserWithConfirmation(user._id)}>
                                                    Ban
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>

                            </div>
                        ))
                    )}

                    <div className="pagination-controls">
                        <button 
                            onClick={() => setCurrentUserPage(currentUserPage - 1)} 
                            disabled={currentUserPage === 1} 
                            className="pagination-button"
                        >
                            Previous
                        </button>
                        <span className="pagination-text">
                            Page {currentUserPage} of {filteredTotalUserPages}
                        </span>
                        <button 
                            onClick={() => setCurrentUserPage(currentUserPage + 1)} 
                            disabled={currentUserPage === filteredTotalUserPages} 
                            className="pagination-button"
                        >
                            Next
                        </button>
                    </div>

                    {/* Confirmation Modal */}
                    {confirmationDialog.visible && (
                        <div className="confirmation-modal">
                            <div className="modal-content">
                                <p>Are you sure you want to proceed with this action?</p>
                                <div className="modal-button-container">
                                <button onClick={confirmAction} className="confirm-button">Yes</button>
                                <button onClick={closeConfirmationDialog} className="cancel-button">No</button>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            )}

        </div>
    );
    
}

export default AdminPanel;
