// components/Logout.js
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
    const navigate = useNavigate();

    useEffect(() => {
        // Remove the token from local storage
        localStorage.removeItem("token");

        // Optionally, you can also clear other user data from local storage
        localStorage.removeItem("user");

        // Redirect to the home page
        navigate("/");
    }, [navigate]);

    return null;  // No UI needed, just handle logout and redirect
}

export default Logout;
