import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./VerifyEmail.css";

function VerifyEmail() {
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(null); // Track success or error for styling
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get("token");

        if (token) {
            axios.post("/api/auth/verify-email", { token })
                .then(() => {
                    setMessage("Email verified successfully!");
                    setIsSuccess(true);
                    setTimeout(() => navigate("/"), 3000);
                })
                .catch(() => {
                    setMessage("Invalid or expired verification link.");
                    setIsSuccess(false);
                });
        }
    }, [navigate]);

    return (
        <div className="verify-email-container">
            <h2 className="verify-email-title">Verify Your Email</h2>
            <p className={`verify-email-message ${isSuccess ? "success" : "error"}`}>
                {message}
            </p>
        </div>
    );
}

export default VerifyEmail;
