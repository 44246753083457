import React, { useState } from "react";
import axios from "axios";

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleForgotPassword = async () => {
        try {
            const res = await axios.post("/api/auth/forgot-password", { email });
            setMessage(res.data.msg);
            setError("");
        } catch (err) {
            setError(err.response?.data?.msg || "Error sending reset email.");
            setMessage("");
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.formContainer}>
                <h2 style={styles.title}>Forgot Password</h2>
                {message && <p style={styles.successMessage}>{message}</p>}
                {error && <p style={styles.errorMessage}>{error}</p>}
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                />
                <button onClick={handleForgotPassword} style={styles.button}>Send Reset Link</button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "var(--background-color)",
    },
    formContainer: {
        backgroundColor: "var(--card-background)",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "var(--text-color)",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        borderRadius: "6px",
        border: `1px solid var(--border-color)`,
        fontSize: "16px",
        backgroundColor: "var(--input-background)",
        color: "var(--input-text)",
        boxSizing: "border-box",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "var(--button-background)",
        color: "var(--button-text)",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "var(--error-color)",
        marginBottom: "10px",
    },
    successMessage: {
        color: "var(--success-color)",
        marginBottom: "10px",
    },
};

export default ForgotPassword;
