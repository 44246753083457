import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserDashboard.css';
import { FaDiscord, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

function UserDashboard() {
    const [userData, setUserData] = useState(null);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newUsername, setNewUsername] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showLogs, setShowLogs] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                navigate("/login");
                return;
            }

            await verifyToken(token);

            const res = await axios.get('/api/auth/dashboard', {
                headers: { Authorization: `Bearer ${token}` },
            });

            setUserData(res.data); 
            setLogs(res.data.logs);
            setLoading(false);
        } catch (err) {
            if (err.response && err.response.status === 403) {
                setError("You are not authorized to access this page. Redirecting to login...");
                setTimeout(() => navigate("/login"), 3000);
            } else {
                console.error("Error fetching user data:", err);
                handleLogout();
            }
        }
    };

    const verifyToken = async (token) => {
        try {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            if (decoded.exp < currentTime) {
                await refreshAccessToken();
            }
        } catch (err) {
            console.log("Token is expired or invalid, logging out:", err);
            handleLogout();
        }
    };

    const refreshAccessToken = async () => {
        try {
            const refreshToken = localStorage.getItem("refreshToken");
            const response = await axios.post('/api/auth/refresh-token', { refreshToken });

            const { accessToken } = response.data;
            localStorage.setItem("token", accessToken);
            return accessToken;
        } catch (err) {
            console.error("Failed to refresh access token, logging out:", err);
            handleLogout();
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
        navigate("/login");
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setMessage("");
        setError("");

        if (!currentPassword && (newUsername || newPassword)) {
            setError("Please enter your current password to update profile.");
            return;
        }

        setUpdating(true);

        try {
            const token = localStorage.getItem("token");
            await axios.put('/api/auth/update', {
                username: newUsername,
                newPassword: newPassword,
                currentPassword: currentPassword,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setMessage("Profile updated successfully!");
            setNewUsername("");
            setNewPassword("");
            setCurrentPassword("");
            setShowCurrentPassword(false);
            fetchUserData();
        } catch (error) {
            setError(error.response?.data?.message || "Error updating profile");
        } finally {
            setUpdating(false);
        }
    };

    const handleFieldChange = () => {
        setShowCurrentPassword(newUsername !== "" || newPassword !== "");
    };

    const formatDiscordUsername = (discord) => {
        if (discord && discord.includes('#0')) {
            return discord.replace('#0', '');
        }
        return discord;
    };

    const handleConnectDiscord = async () => {
        setError("");
    
        try {
            const token = localStorage.getItem("token");
            const userId = JSON.parse(atob(token.split('.')[1])).userId; 
    
            if (!userId) {
                throw new Error("User ID not found. Please log in again.");
            }
    
            setMessage("Redirecting to Discord to connect your account...");
    
            setTimeout(async () => {
                const res = await axios.get(`/api/auth/discord/url?userId=${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                
                window.location.href = res.data.url;
            }, 2000); 
        } catch (error) {
            setError("Failed to initiate Discord connection.");
            console.error("Error initiating Discord connection:", error);
        }
    };
    

    if (loading) return <p>Loading...</p>;

    return (
        <div className="page-wrapper">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>User Dashboard - Sintrix.net</title>
                <meta name="description" content="Manage your account, connect with Discord, and update your profile information on your Sintrix.net dashboard." />
            </Helmet>

            {userData ? (
                <div className="user-dashboard">
                    <div className="dashboard-header">
                        <h1>Welcome, {userData.username}</h1>
                        {userData.discordAvatar && (
                            <img
                                src={userData.discordAvatar}
                                alt="Discord Avatar"
                                className="dashboard-avatar"
                            />
                        )}
                        {userData.discord !== "Not Connected" ? (
                            <p className="discord-name">
                                <FaDiscord className="discord-icon" /> {formatDiscordUsername(userData.discord)}
                            </p>
                        ) : (
                            <div className="discord-connect">
                                <button onClick={handleConnectDiscord} className="connect-discord-button">
                                    <FaDiscord className="discord-icon" /> Connect Discord
                                </button>
                            </div>
                        )}
                        <p className="user-email">Email: {userData.email}</p>
                    </div>

                    <h3>Update Profile</h3>
                    {message && <p className="success-message"><FaCheckCircle /> {message}</p>}
                    {error && <p className="error-message"><FaExclamationTriangle /> {error}</p>}

                    <form onSubmit={handleUpdate} autoComplete="off">
                        {showCurrentPassword && (
                            <input
                                type="password"
                                placeholder="Current Password"
                                value={currentPassword}
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                name="current-password-field"
                                id="current-password-field"
                                autoComplete="new-password"
                                required
                            />
                        )}
                        <input
                            type="text"
                            placeholder="New Username"
                            value={newUsername}
                            onChange={(e) => {
                                setNewUsername(e.target.value);
                                handleFieldChange();
                            }}
                            name="new-username-field"
                            id="new-username-field"
                            autoComplete="off"
                        />
                        <input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                                handleFieldChange();
                            }}
                            name="new-password-field"
                            id="new-password-field"
                            autoComplete="new-password"
                        />
                        <button type="submit" disabled={updating}>
                            {updating ? "Updating..." : "Update Profile"}
                        </button>
                    </form>
                    <div className="dashboard-logs">
                    <h3 onClick={() => setShowLogs(!showLogs)} style={{ cursor: 'pointer' }}>
                        Votes {showLogs ? '▼' : '▶'}
                    </h3>
                    {showLogs && (
                        <ul>
                            {logs.map((log, index) => (
                                <li key={index}>
                                    {log.activity} - {log.gameName} - {new Date(log.timestamp).toLocaleString()}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>


                </div>
            ) : (
                <p>Error fetching user data.</p>
            )}
        </div>
    );
}

export default UserDashboard;
