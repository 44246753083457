// DiscordButton.js
import React from 'react';
import './DiscordButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

function DiscordButton() {
    const handleDiscordClick = () => {
        window.open('https://discord.gg/zjvdGQBrBc', '_blank');
    };

    return (
        <div className="discord-button" onClick={handleDiscordClick}>
            <FontAwesomeIcon icon={faDiscord} size="2x" />
        </div>
    );
}

export default DiscordButton;
