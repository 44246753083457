import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

function Signup() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [resendMessage, setResendMessage] = useState(""); // State for resend message
    const navigate = useNavigate();

    const handleSignup = async () => {
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            await axios.post("/api/auth/signup", { username, email, password });
            setSuccessMessage("Signup successful! Please check your email to verify your account. Remember to check your spam or junk if you do not see the email.");
            setError("");
        } catch (err) {
            setError(err.response?.data?.msg || "Error signing up");
        }
    };

    const handleResendEmail = async () => {
        try {
            await axios.post("/api/auth/resend-verification", { email });
            setResendMessage("Verification email has been resent. Please check your inbox.");
            setError("");
        } catch (err) {
            setError(err.response?.data?.msg || "Error resending verification email.");
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSignup();
        }
    };

    return (
        <div style={styles.container}>
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>Signup - Sintrix.net</title>
                <meta name="description" content="Create an account on Sintrix.net to access exclusive cheat tables and features. Join the community today!" />
            </Helmet>

            <div style={styles.formContainer}>
                <h2 style={styles.title}>Signup</h2>

                {/* Warning Message */}
                <div style={styles.warningBox}>
                    <p>
                        <strong>Note:</strong> Due to Microsoft email policies, users with Microsoft email accounts
                        (e.g., Outlook, Hotmail) may not receive our verification emails. Please join our 
                        <a href="https://discord.gg/zjvdGQBrBc" target="_blank" rel="noopener noreferrer" style={styles.link}> Discord server </a> 
                         for assistance.
                    </p>
                </div>

                {successMessage ? (
                    <>
                        <p style={styles.successMessage}>{successMessage}</p>
                        {resendMessage && <p style={styles.resendMessage}>{resendMessage}</p>}
                        <button onClick={handleResendEmail} style={styles.resendButton}>Resend Verification Email</button>
                    </>
                ) : (
                    <>
                        {error && <p style={styles.errorMessage}>{error}</p>}
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                        />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                        />
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={styles.input}
                        />
                        <button onClick={handleSignup} style={styles.button}>Signup</button>
                        <p style={styles.linkText}>
                            Already have an account? <Link to="/login" style={styles.link}>Login here</Link>
                        </p>
                    </>
                )}
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f2f2f2",
    },
    formContainer: {
        backgroundColor: "#ffffff",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "#81078c",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        borderRadius: "6px",
        border: "1px solid #ccc",
        fontSize: "16px",
        boxSizing: "border-box",
        backgroundColor: "#f0f4f8",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "#8319d4",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    resendButton: {
        padding: "12px 20px",
        backgroundColor: "#555",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "red",
        marginBottom: "10px",
    },
    successMessage: {
        color: "green",
        marginBottom: "10px",
    },
    resendMessage: {
        color: "blue",
        marginBottom: "10px",
    },
    linkText: {
        marginTop: "20px",
        fontSize: "14px",
        color: "#555",
    },
    link: {
        color: "#8319d4",
        textDecoration: "none",
        fontWeight: "bold",
        transition: "color 0.3s ease",
    },
    warningBox: {
        backgroundColor: "#ffebcc",
        color: "#663300",
        border: "1px solid #ffa500",
        borderRadius: "8px",
        padding: "15px",
        marginBottom: "20px",
        fontSize: "14px",
        textAlign: "left",
    },
};

export default Signup;
