import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import './CategoryDetail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTimes } from '@fortawesome/free-solid-svg-icons';

function CategoryDetail() {
    const { id: slug } = useParams();
    const [metadata, setMetadata] = useState(null);
    const [category, setCategory] = useState(null);
    const [latestTable, setLatestTable] = useState(null);
    const [tables, setTables] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tablesLoading, setTablesLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isExpanded, setIsExpanded] = useState(false);

    // Fetch metadata for SEO
    const fetchMetadata = async () => {
        try {
            const response = await axios.get(`/metadata/${slug}`);
            setMetadata(response.data);
        } catch (error) {
            console.error("Error fetching metadata:", error);
        }
    };

    // Fetch category details
    const fetchCategoryDetails = async () => {
        try {
            const response = await axios.get(`/api/admin/categories/${slug}`);
            setCategory(response.data);
        } catch (error) {
            console.error("Error fetching category details:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch tables
    const fetchTables = async () => {
        setTablesLoading(true);
        try {
            const response = await axios.get(`/api/admin/tables/${slug}`);
            const sortedTables = response.data.sort((a, b) => {
                const versionA = a.version.split('.').map(Number);
                const versionB = b.version.split('.').map(Number);
                for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
                    const numA = versionA[i] || 0;
                    const numB = versionB[i] || 0;
                    if (numA !== numB) {
                        return numB - numA;
                    }
                }
                return 0;
            });
            setTables(sortedTables);
            if (sortedTables.length > 0) {
                setLatestTable(sortedTables[0]);
            }
        } catch (error) {
            console.error("Error fetching tables:", error);
        } finally {
            setTablesLoading(false);
        }
    };

    useEffect(() => {
        fetchMetadata();
        fetchCategoryDetails();
        fetchTables();
    }, [slug]);

    // Handle table download
    const handleTableDownload = async (table) => {
        try {
            const response = await axios.get(`/api/admin/download-table/${table._id}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${category.slug}-v${table.version}.CT`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            fetchTables(); // Fetch updated tables to reflect the download count
        } catch (error) {
            console.error("Error downloading table:", error);
        }
    };

    const toggleShowMore = () => setShowMore(!showMore);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % category.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + category.images.length) % category.images.length);
    };

    const handleExpandImage = () => {
        setIsExpanded(true);
    };

    const closeExpandImage = () => {
        setIsExpanded(false);
    };

    const formatYouTubeEmbedUrl = (url) => {
        const videoIdMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return videoIdMatch ? `https://www.youtube.com/embed/${videoIdMatch[1]}` : url;
    };

    if (loading) {
        return <p>Loading game details...</p>;
    }

    return (
        <div className="category-detail-container">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>{metadata?.title || "Loading..."}</title>
                <meta name="description" content={metadata?.description || "Loading..."} />
                <meta property="og:title" content={metadata?.title || "Loading..."} />
                <meta property="og:description" content={metadata?.description || "Loading..."} />
                <meta property="og:image" content={metadata?.image || "https://sintrix.net/sintrix2.png"} />
                <meta property="og:url" content={metadata?.url || "https://sintrix.net"} />
            </Helmet>

            <h2 className="category-title">{category.name}</h2>

            {/* Display message if the game is discontinued */}
            {category.discontinued && (
                <p className="discontinued-message">This game is discontinued and will not be receiving updates.</p>
            )}

            <div className="category-content">
                {/* Left Side: Image showcase and videos */}
                <div className="category-media">
                    {/* Image Slideshow */}
                    {category.images && category.images.length > 0 ? (
                        <div className="image-slideshow">
                            <img
                                src={category.images[currentImageIndex]}
                                alt={`Screenshot ${currentImageIndex + 1}`}
                                className="category-screenshot"
                                onClick={handleExpandImage}
                            />
                            <button className="prev-btn" onClick={prevImage}>
                                <FontAwesomeIcon icon={faChevronLeft} />
                            </button>
                            <button className="next-btn" onClick={nextImage}>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    ) : (
                        <p>No images available.</p>
                    )}

                    {/* Expanded Image View */}
                    {isExpanded && (
                        <div className="expanded-image-overlay">
                            <div className="expanded-image-container">
                                <img
                                    src={category.images[currentImageIndex]}
                                    alt={`Screenshot ${currentImageIndex + 1}`}
                                    className="expanded-image"
                                />
                                <button className="close-btn" onClick={closeExpandImage}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    )}

                    {/* Video section */}
                    <div className="video-showcase">
                        {category.video ? (
                            <iframe
                                width="420"
                                height="315"
                                src={formatYouTubeEmbedUrl(category.video)}
                                title="Video Showcase"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <p>No video available.</p>
                        )}
                    </div>
                </div>

                {/* Right Side: Description from latest table and downloads */}
                <div className="category-description">
                    <h3>Description (from latest table)</h3>
                    <div>
                        {latestTable ? (
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: latestTable.description.replace(/\n/g, '<br />'),
                                }}
                            />
                        ) : (
                            <p>No description available for the latest table.</p>
                        )}
                    </div>

                    {tablesLoading ? (
                        <p>Loading tables...</p>
                    ) : (
                        <>
                            <h3>Available Tables</h3>
                            {tables.length === 0 ? (
                                <p>No tables available for this game.</p>
                            ) : (
                                <>
                                    {tables.slice(0, showMore ? tables.length : 1).map((table) => (
                                        <div key={table._id} className="table-item">
                                            <p>Version: {table.version}</p>
                                            <p>Downloads: {table.downloadCount || 0}</p>
                                            <button onClick={() => handleTableDownload(table)} className="download-link">
                                                Download v{table.version}
                                            </button>
                                        </div>
                                    ))}
                                    {tables.length > 1 && (
                                        <a href="#!" onClick={toggleShowMore} className="show-more-link">
                                            {showMore ? (
                                                <FontAwesomeIcon icon={faChevronUp} />
                                            ) : (
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            )}
                                        </a>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Disclaimer Section */}
            <div className="disclaimer">
                <p>
                    Disclaimer: We are not responsible for any loss of account, data, or damage resulting from the use of these tables. Use at your own risk.
                </p>
            </div>
        </div>
    );
}

export default CategoryDetail;
