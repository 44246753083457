import React from "react";
import { useLocation } from "react-router-dom";

const ErrorPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get("message") || "An error occurred";

    return (
        <div style={styles.container}>
            <div style={styles.errorBox}>
                <h1 style={styles.title}>Oops! Something went wrong</h1>
                <p style={styles.message}>{message}</p>
                <a href="/" style={styles.homeLink}>Go back to Homepage</a>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f0f4f8",
    },
    errorBox: {
        textAlign: "center",
        color: "#333",
        padding: "40px",
        borderRadius: "12px",
        backgroundColor: "#fff",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
        maxWidth: "400px",
        width: "100%",
    },
    title: {
        fontSize: "24px",
        color: "#d9534f",  // Red color for error title
        marginBottom: "20px",
        fontWeight: "bold",
    },
    message: {
        fontSize: "18px",
        color: "#555",
        marginBottom: "30px",
    },
    homeLink: {
        fontSize: "16px",
        color: "#007bff",  // Bootstrap primary blue color
        textDecoration: "none",
        fontWeight: "bold",
        border: "1px solid #007bff",
        padding: "10px 20px",
        borderRadius: "5px",
        transition: "background-color 0.3s ease, color 0.3s ease",
    },
    homeLinkHover: {
        backgroundColor: "#007bff",
        color: "#fff",
    },
};

export default ErrorPage;
