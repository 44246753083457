import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import "./Home.css";

function Home() {
    const navigate = useNavigate();

    return (
        <div className="home-container">
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>Sintrix</title>
                <meta name="description" content="Welcome to Sintrix, a platform for downloading and submitting requests for Cheat Engine tables. Explore cheat tables and make requests!" />
            </Helmet>

            <div className="home-content">
                <img src="/logo192.png" alt="Logo" className="logo" onDoubleClick={() => navigate('/redpill')}/>
                <h1 className="home-title">
                    Welcome to Sintrix
                </h1>
                <hr className="separator-line" />
                <p className="home-description">
                    A platform for downloading and submitting requests for Cheat Engine tables.
                </p>
                <div className="home-buttons">
                    <Link to="/requests" className="home-button">
                        Cheat Requests
                    </Link>
                    <Link to="/games" className="home-button">
                        Tables
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
