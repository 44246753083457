import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const handleLogin = async () => {
        try {
            const res = await axios.post("/api/auth/login", { username, password });
            localStorage.setItem("token", res.data.accessToken);
            localStorage.setItem("refreshToken", res.data.refreshToken); 
            
            // Redirect to homepage after successful login
            window.location.href = "/";
        } catch (err) {
            // Custom error messages based on response
            const errorMessage = err.response?.data?.msg;
            if (errorMessage === "Your account is banned. Please contact support.") {
                setError(errorMessage);
            } else if (errorMessage === "Please verify your email before logging in.") {
                setError(errorMessage);
            } else {
                setError("Invalid username or password.");
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    return (
        <div style={styles.container}>
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>Login - Sintrix.net</title>
                <meta name="description" content="Login to access your account on Sintrix.net. Enter your username and password to get started." />
            </Helmet>

            <div style={styles.formContainer}>
                <h2 style={styles.title}>Login</h2>
                {error && <p style={styles.errorMessage}>{error}</p>}
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={styles.input}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    style={styles.input}
                />
                <button onClick={handleLogin} style={styles.button}>Login</button>
                <p style={styles.linkText}>
                    Forgot password? <Link to="/forgot-password" style={styles.link}>Reset it here</Link>
                </p>
                <p style={styles.linkText}>
                    Don't have an account? <Link to="/signup" style={styles.link}>Sign up here</Link>
                </p>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f2f2f2",
    },
    formContainer: {
        backgroundColor: "#ffffff",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "#81078c",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        borderRadius: "6px",
        border: "1px solid #ccc",
        fontSize: "16px",
        boxSizing: "border-box",
        backgroundColor: "#f0f4f8",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "#8319d4",
        color: "#fff",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "red",
        marginBottom: "10px",
    },
    linkText: {
        marginTop: "20px",
        fontSize: "14px",
        color: "#555",
    },
    link: {
        color: "#8319d4",
        textDecoration: "none",
        fontWeight: "bold",
        transition: "color 0.3s ease",
    },
};

export default Login;
