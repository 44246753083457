import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle, FaSun, FaMoon } from "react-icons/fa";

function Navbar() {
    const [token, setToken] = useState(localStorage.getItem("token"));
    const [isAdmin, setIsAdmin] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => localStorage.getItem("theme") === "dark");
    const [hoveredItem, setHoveredItem] = useState(null); // For hover states on links
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    useEffect(() => {
        if (token) {
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
            setIsAdmin(decodedToken && decodedToken.role === "admin");
        }
    }, [token]);

    // Listen for custom "storageUpdate" event to detect token changes
    useEffect(() => {
        const updateToken = () => setToken(localStorage.getItem("token"));

        // Add event listener for custom "storageUpdate" event
        window.addEventListener("storageUpdate", updateToken);

        return () => {
            window.removeEventListener("storageUpdate", updateToken);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        document.body.className = isDarkMode ? "dark" : "light";
        localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    }, [isDarkMode]);

    const toggleTheme = () => setIsDarkMode((prevMode) => !prevMode);

    const handleLogout = () => {
        localStorage.removeItem("token");
        setToken(null);
        setIsAdmin(false);
        setShowMenu(false);
        navigate("/login");
    };

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    return (
        <nav style={styles.navbar}>
            <div style={styles.navLinks}>
                <Link to="/" style={styles.logoLink}>
                    <img src="/logo192.png" alt="Logo" style={styles.logo} />
                </Link>
                <Link
                    to="/requests"
                    style={{
                        ...styles.link,
                        ...(hoveredItem === "requests" && styles.hoverEffect),
                    }}
                    onMouseEnter={() => setHoveredItem("requests")}
                    onMouseLeave={() => setHoveredItem(null)}
                >
                    Requests
                </Link>
                <Link
                    to="/games"
                    style={{
                        ...styles.link,
                        ...(hoveredItem === "tables" && styles.hoverEffect),
                    }}
                    onMouseEnter={() => setHoveredItem("tables")}
                    onMouseLeave={() => setHoveredItem(null)}
                >
                    Tables
                </Link>
                <Link
                    to="/support"
                    style={{
                        ...styles.link,
                        ...(hoveredItem === "support" && styles.hoverEffect),
                    }}
                    onMouseEnter={() => setHoveredItem("support")}
                    onMouseLeave={() => setHoveredItem(null)}
                >
                    Support
                </Link>
            </div>
            <div style={styles.authLinks}>
                {token ? (
                    <div style={styles.personIconWrapper} ref={dropdownRef}>
                        <FaUserCircle size={32} onClick={toggleMenu} style={{ color: "white", cursor: "pointer" }} />
                        {showMenu && (
                            <div style={styles.dropdownMenu}>
                                <Link
                                    to="/dashboard"
                                    style={{
                                        ...styles.dropdownItem,
                                        ...(hoveredItem === "dashboard" && styles.hoverEffect),
                                    }}
                                    onMouseEnter={() => setHoveredItem("dashboard")}
                                    onMouseLeave={() => setHoveredItem(null)}
                                    onClick={() => setShowMenu(false)}
                                >
                                    Dashboard
                                </Link>
                                {isAdmin && (
                                    <Link
                                        to="/admin"
                                        style={{
                                            ...styles.dropdownItem,
                                            ...(hoveredItem === "admin" && styles.hoverEffect),
                                        }}
                                        onMouseEnter={() => setHoveredItem("admin")}
                                        onMouseLeave={() => setHoveredItem(null)}
                                        onClick={() => setShowMenu(false)}
                                    >
                                        Admin Panel
                                    </Link>
                                )}
                                <button
                                    onClick={toggleTheme}
                                    style={{
                                        ...styles.themeToggleButton,
                                        ...(hoveredItem === "theme" && styles.hoverEffect),
                                    }}
                                    onMouseEnter={() => setHoveredItem("theme")}
                                    onMouseLeave={() => setHoveredItem(null)}
                                >
                                    {isDarkMode ? "Light Mode" : "Dark Mode"}
                                    {isDarkMode ? <FaSun style={styles.icon} /> : <FaMoon style={styles.icon} />}
                                </button>
                                <button
                                    onClick={handleLogout}
                                    style={{
                                        ...styles.logoutButton,
                                        ...(hoveredItem === "logout" && styles.hoverEffect),
                                    }}
                                    onMouseEnter={() => setHoveredItem("logout")}
                                    onMouseLeave={() => setHoveredItem(null)}
                                >
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Link
                            to="/login"
                            style={{
                                ...styles.link,
                                ...(hoveredItem === "login" && styles.hoverEffect),
                            }}
                            onMouseEnter={() => setHoveredItem("login")}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            Login
                        </Link>
                        <Link
                            to="/signup"
                            style={{
                                ...styles.link,
                                ...(hoveredItem === "signup" && styles.hoverEffect),
                            }}
                            onMouseEnter={() => setHoveredItem("signup")}
                            onMouseLeave={() => setHoveredItem(null)}
                        >
                            Signup
                        </Link>
                    </>
                )}
            </div>
        </nav>
    );
}

const styles = {
    navbar: {
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        background: "linear-gradient(90deg, #81078c, #8319d4)",
        boxShadow: "0 8px 12px rgba(0, 0, 0, 0.4)",
        borderBottom: "1px solid #aaa",
    },
    navLinks: {
        display: "flex",
        gap: "20px",
    },
    authLinks: {
        display: "flex",
        gap: "20px",
    },
    logoLink: {
        textDecoration: "none",
    },
    link: {
        textDecoration: "none",
        fontSize: "16px",
        fontWeight: "500",
        padding: "8px 12px",
        borderRadius: "4px",
        color: "white",
        transition: "color 0.3s ease, background-color 0.3s ease",
    },
    hoverEffect: {
        color: "#007bff",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    personIconWrapper: {
        position: "relative",
    },
    dropdownMenu: {
        position: "absolute",
        top: "45px",
        right: 0,
        borderRadius: "8px",
        width: "180px",
        padding: "10px 0",
        backgroundColor: "var(--card-background)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
    },
    dropdownItem: {
        display: "block",
        padding: "10px 15px",
        fontSize: "16px",
        textDecoration: "none",
        color: "var(--text-color)",
        cursor: "pointer",
        transition: "background-color 0.3s ease, color 0.3s ease",
    },
    themeToggleButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 15px",
        fontSize: "16px",
        fontWeight: "500",
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        color: "var(--text-color)",
        transition: "background-color 0.3s ease, color 0.3s ease",
    },
    logoutButton: {
        display: "block",
        width: "100%",
        padding: "10px 15px",
        fontSize: "16px",
        fontWeight: "500",
        textAlign: "left",
        color: "#d12c28",
        cursor: "pointer",
        backgroundColor: "transparent",
        border: "none",
        transition: "background-color 0.3s ease, color 0.3s ease",
    },
    icon: {
        marginLeft: "8px",
    },
    logo: {
        height: "40px",
        width: "auto",
        cursor: "pointer",
    },
};

export default Navbar;
