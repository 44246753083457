import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet for SEO

function ResetPassword() {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get("token");
        if (!token) {
            setMessage("Invalid or expired reset link.");
        }
    }, [location]);

    const handlePasswordReset = async () => {
        if (newPassword !== confirmPassword) {
            setMessage("Passwords do not match.");
            return;
        }
    
        try {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get("token");
            console.log("Token being sent:", token); // Debugging
    
            if (!token) {
                setMessage("Invalid or expired reset link.");
                return;
            }
    
            const response = await axios.post("/api/auth/reset-password", { token, newPassword });
            setMessage("Password reset successfully. Redirecting to login...");
            setTimeout(() => navigate("/login"), 3000);
        } catch (error) {
            setMessage(error.response?.data?.msg || "Error resetting password.");
        }
    };

    return (
        <div style={styles.container}>
            {/* Dynamic SEO with Helmet */}
            <Helmet>
                <title>Reset Password - Sintrix.net</title>
                <meta name="description" content="Reset your password for your Sintrix.net account. Enter a new password to regain access." />
            </Helmet>

            <div style={styles.formContainer}>
                <h2 style={styles.title}>Reset Password</h2>
                {message && (
                    <p style={message.includes("successfully") ? styles.successMessage : styles.errorMessage}>
                        {message}
                    </p>
                )}
                <input
                    type="password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={styles.input}
                />
                <input
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={styles.input}
                />
                <button onClick={handlePasswordReset} style={styles.button}>Reset Password</button>
            </div>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "var(--background-color)",
    },
    formContainer: {
        backgroundColor: "var(--card-background)",
        padding: "40px 20px",
        borderRadius: "16px",
        boxShadow: "0 10px 20px rgba(0, 0, 0, 0.1)",
        width: "100%",
        maxWidth: "400px",
        textAlign: "center",
    },
    title: {
        color: "var(--text-color)",
        marginBottom: "20px",
        fontWeight: "bold",
    },
    input: {
        padding: "12px",
        width: "100%",
        margin: "10px 0",
        borderRadius: "6px",
        border: `1px solid var(--border-color)`,
        fontSize: "16px",
        backgroundColor: "var(--input-background)",
        color: "var(--input-text)",
    },
    button: {
        padding: "12px 20px",
        backgroundColor: "var(--button-background)",
        color: "var(--button-text)",
        border: "none",
        borderRadius: "6px",
        cursor: "pointer",
        width: "100%",
        marginTop: "10px",
        fontSize: "16px",
        transition: "background-color 0.3s ease",
    },
    errorMessage: {
        color: "var(--error-color)",
        marginBottom: "10px",
    },
    successMessage: {
        color: "var(--success-color)",
        marginBottom: "10px",
    },
};

export default ResetPassword;
